<template>
    <LiefengContent :isBack="true" @backClick="goBack">
        <template #title>
            群发邮箱管理
            <!-- <Button
        type="error"
        @click="goBack"
        >返回</Button
      > -->
        </template>
        <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input style="width: 260px" v-model.trim="userName" placeholder="按姓名查询" search @on-search="search" enter-button="查询"></Input>
                </FormItem>
            </Form>
            <Button icon="ios-add" @click="massMessaging" type="primary" style="margin-right: 0">群发消息</Button>
        </template>
        <template #contentArea>
            <LiefengTable
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :pageSize="pageSize"
                :pagesizeOpts="[20, 30, 50, 100, 200]"
                :total="total"
                :fixTable="true"
                :curPage="page"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
            <!-- 发消息 -->
            <LiefengModal :value="sendStaus" title="发消息" width="800px" height="calc(100vh - 200px)" @input="sendStausFn">
                <template v-slot:contentarea>
                    <Button type="primary" style="margin:0 0  14px 70px" @click="sendeeStatus = true">选择接收人</Button>
                    <Form ref="sendForm" :model="sendObj" :rules="validateSend" :label-width="70" label-colon>
                        <FormItem label="接收人" prop="userName">
                            <Input disabled v-model="sendObj.userName" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请选择接收人"></Input>
                        </FormItem>
                        <!-- <FormItem label="标题" prop="title">
              <Input
                v-model.trim="sendObj.title"
                placeholder="请输入标题，限30字"
              ></Input>
            </FormItem> -->
                        <FormItem label="详情" prop="content">
                            <Input
                                v-model="sendObj.content"
                                :maxlength="800"
                                show-word-limit
                                @on-change="textareaOnChange"
                                type="textarea"
                                :autosize="{ minRows: 5, maxRows: 8 }"
                                placeholder="请输入详情"
                            ></Input>
                        </FormItem>
                        <FormItem label="方式">
                            <RadioGroup v-model="sendChannel">
                                <Radio label="1" :disabled="isOpen">仅发短信</Radio>
                                <Radio label="2">仅发居民邮箱消息</Radio>
                                <Radio label="0" :disabled="isOpen">发短信和居民邮箱消息</Radio>
                            </RadioGroup>
                        </FormItem>
                        <div class="title" style="padding-left:100px;font-size:16px" v-if="sendChannel == 1 || sendChannel == 0">
                            当前将发送短信共:
                            <span style="color:red">{{ resTotal || 0 }}</span>
                            条，预计费用:
                            <span style="color:red">{{ resFee.toFixed(2) || 0 }} 元</span>
                        </div>
                        <div class="title" style="padding-left:100px;" v-if="sendChannel == 1 || sendChannel == 0">
                            <div style="margin:10px 0">计费说明:</div>
                            <div style="margin:10px 0">1、短信费用为0.07元/条；</div>
                            <div style="margin:10px 0">2、每70个字算1条。</div>
                        </div>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button
                        type="info"
                        @click="
                            cancelModalTip = true
                            sendStausFn(false)
                        "
                        style="margin-right: 10px"
                    >
                        取消
                    </Button>
                    <Button type="primary" @click="groupSend">确定</Button>
                </template>
            </LiefengModal>
            <!-- 选择接收人 -->
            <LiefengModal :value="sendeeStatus" title="选择接收人" :fullscreen="true" @input="sendStatusFn">
                <template v-slot:contentarea>
                    <div class="container-div" v-if="sendStaus">
                        <div class="tree-div">
                            <LiefengTree :fixedmenu="true" treeID="mychild" style="width: 200px; margin-top: 10px" ref="mychild" @handleData="getList"></LiefengTree>
                        </div>
                        <div class="center-table">
                            <div style="margin-bottom: 10px; display: flex">
                                <Input v-model="treeParams.name" @on-search="sendSearch" search enter-button="查询" style="width: 560px; margin-right: 10px" placeholder="通过姓名查询"></Input>
                                <Button type="success" icon="ios-refresh" @click="sendReset">重置</Button>
                            </div>
                            <div class="table">
                                <LiefengTable
                                    :tableData="sendTableData"
                                    :talbeColumns="sendTableColumns"
                                    :loading="sendLoading"
                                    :pageSize="sendPageSize"
                                    :pagesizeOpts="[20, 30, 50, 100, 200]"
                                    :total="sendTotal"
                                    :height="tableHeight"
                                    @tableSelect="tableSelect"
                                    :curPage="sendPage"
                                    @hadlePageSize="sendHadlePageSize"
                                ></LiefengTable>
                            </div>
                            <!-- <LiefengTable
                  :tableData="sendTableData"
                  :talbeColumns="sendTableColumns"
                  :loading="sendLoading"
                  :pageSize="sendPageSize"
                  :pagesizeOpts="[20, 30, 50, 100, 200]"
                  :total="sendTotal"
                  :height="tableHeight"
                  @tableSelect="tableSelect"
                  :curPage="sendPage"
                  @hadlePageSize="sendHadlePageSize"
                  class="select-table"
                ></LiefengTable> -->
                        </div>
                        <div class="right-table">
                            <div class="right-center">
                                <Button type="primary" style="margin-bottom: 14px" @click="pushToRightList">右移</Button>
                                <Button type="error" style="margin-bottom: 14px" @click="delRightList">删除</Button>
                                <Button type="warning" @click="clearRightList">清空</Button>
                            </div>

                            <div class="right-right">
                                <div
                                    style="
                      text-align: center;
                      font-size: 14px;
                      font-weight: bold;
                      line-height: 40px;
                      color: #515a6e;
                      background: #f8f8f9;
                    "
                                >
                                    已选择
                                </div>
                                <List border size="small">
                                    <ListItem v-for="(item, index) in rightList" :key="item.id">
                                        <div style="width: 100%; display: block" @click="selectRightList($event, index)">
                                            {{ item.name }}
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        </div>

                        <!-- <div class="right-table">
              <div class="right-center">
                <Button
                  type="primary"
                  style="margin-bottom: 14px"
                  @click="pushToRightList"
                  >右移</Button
                >
                <Button
                  type="error"
                  style="margin-bottom: 14px"
                  @click="delRightList"
                  >删除</Button
                >
                <Button type="warning" @click="clearRightList">清空</Button>
            </div>
              <div class="right-right">
                 <div
                  style="
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 40px;
                    color: #515a6e;
                    background: #f8f8f9;
                  "
                >
                  已选择
                </div>
                <List border size="small">
                  <ListItem v-for="(item, index) in rightList" :key="item.id"
                    ><div
                      style="width: 100%; display: block"
                      @click="selectRightList($event, index)"
                    >
                      {{ item.name }}
                    </div></ListItem
                  >
                </List>
              </div>
               
            </div> -->
                    </div>
                    <!-- <div v-if="sendStaus">
            <LiefengTree
              :height="treeHeight"
              :fixedmenu="true"
              treeID="mychild"
              style="width: 200px; margin-top: 10px"
              ref="mychild"
              @handleData="getList"
            >
            </LiefengTree>

            <div style="display: flex; margin-left: 210px">
              <div style="flex: 2">
                <div style="margin-bottom: 10px; display: flex">
                  <Input
                    v-model="treeParams.name"
                    @on-search="sendSearch"
                    search
                    enter-button="查询"
                    style="width: 560px; margin-right: 10px"
                    placeholder="通过姓名查询"
                  ></Input>
                  <Button type="success" icon="ios-refresh" @click="sendReset"
                    >重置</Button
                  >
                </div>
                <LiefengTable
                  :tableData="sendTableData"
                  :talbeColumns="sendTableColumns"
                  :loading="sendLoading"
                  :pageSize="sendPageSize"
                  :pagesizeOpts="[20, 30, 50, 100, 200]"
                  :total="sendTotal"
                  :height="tableHeight"
                  @tableSelect="tableSelect"
                  :curPage="sendPage"
                  @hadlePageSize="sendHadlePageSize"
                  class="select-table"
                ></LiefengTable>
              </div>
              <div
                style="
                  width: 120px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <Button
                  type="primary"
                  style="margin-bottom: 14px"
                  @click="pushToRightList"
                  >右移</Button
                >
                <Button
                  type="error"
                  style="margin-bottom: 14px"
                  @click="delRightList"
                  >删除</Button
                >
                <Button type="warning" @click="clearRightList">清空</Button>
              </div>
              <div
                style="width: 200px; border: 1px solid #dcdee2; overflow: auto"
              >
                <div
                  style="
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 40px;
                    color: #515a6e;
                    background: #f8f8f9;
                  "
                >
                  已选择
                </div>
                <List border size="small">
                  <ListItem v-for="(item, index) in rightList" :key="item.id"
                    ><div
                      style="width: 100%; display: block"
                      @click="selectRightList($event, index)"
                    >
                      {{ item.name }}
                    </div></ListItem
                  >
                </List>
              </div>
            </div>
          </div> -->
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="sendStatusFn(false)" style="margin-right: 10px">关闭</Button>
                    <Button type="primary" @click="saveRightList">确定</Button>
                </template>
            </LiefengModal>
            <LiefengModal title="短信开通提醒" :value="openTips" @input="changeOpenTips" width="500px" height="200px">
                <template v-slot:contentarea>
                    <p style="fontSize:16px">{{ orgName }}还未开通短信服务，通过随约系统发送短信，会产生短信费最高0.07元/条，是否提交开通此项服务的申请？如有疑问请联系社区实施专管员。</p>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" style="marginRight:10px" :loading="opLoading" @click="goOpen">我要申请开通</Button>
                    <Button @click="notOpen">暂不开通</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/emailgroup')
import LiefengTree from "@/components/LiefengTree"
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            isOpen: false,
            openTips: false,
            orgName: "",

            userName: "",
            tableData: [],
            talbeColumns: [
                {
                    title: "接收人",
                    key: "userName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "内容",
                    key: "content",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "发送时间",
                    key: "gmtCreate",
                    width: 180,
                    align: "center",
                },
                {
                    title: "共发送户数",
                    key: "recSum",
                    width: 140,
                    align: "center",
                },
                {
                    title: "已查看户数",
                    key: "viewNum",
                    width: 140,
                    align: "center",
                },
                {
                    title: "操作",
                    width: 260,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "warning",
                                    },
                                    style: {
                                        display: params.row.recSum > params.row.viewNum ? "inline-block" : "none",
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$post("/sendmsg/pc/chat/communityMessage/sendNoneViewUser", {
                                                messageId: params.row.messageId,
                                            }).then(res => {
                                                if (res.code == 200) {
                                                    this.$Message.success({
                                                        background: true,
                                                        content: "再次提醒成功",
                                                    })
                                                } else {
                                                    this.$Message.error({
                                                        background: true,
                                                        content: "再次提醒失败",
                                                    })
                                                }
                                            })
                                        },
                                    },
                                },
                                "再次提醒"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "primary",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$router.push({
                                                path: "/emailsee",
                                                query: {
                                                    id: params.row.messageId,
                                                    menuId: this.$route.query.menuId,
                                                    isLineHome: this.$route.query.isLineHome ? this.$route.query.isLineHome : "",
                                                },
                                            })
                                        },
                                    },
                                },
                                "谁查看了"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                        type: "error",
                                    },
                                    on: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "温馨提示",
                                                content: "<p>您正在删除邮箱信息，此操作不可逆，是否继续？</p>",
                                                okText: "确定",
                                                loading: true,
                                                cancelText: "取消",
                                                onOk: () => {
                                                    this.$post("/sendmsg/pc/chat/communityMessage/deleteCommunityMessage", {
                                                        messageId: params.row.messageId,
                                                    })
                                                        .then(res => {
                                                            if (res.code == 200) {
                                                                this.$Message.success({
                                                                    background: true,
                                                                    content: "删除成功",
                                                                })
                                                                this.$Modal.remove()
                                                                this.hadlePageSize({
                                                                    page: this.page,
                                                                    pageSize: this.pageSize,
                                                                })
                                                            } else {
                                                                this.$Message.error({
                                                                    background: true,
                                                                    content: res.desc,
                                                                })
                                                                this.$Modal.remove()
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.log(err)
                                                            this.$Message.error({
                                                                background: true,
                                                                content: "删除失败，请联系管理员处理",
                                                            })
                                                            this.$Modal.remove()
                                                        })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,

            // 发消息
            sendStaus: false,
            cancelModalTip: false,
            resSendTotal: 0,
            resTotal: 0,
            resFee: 0,
            sendObj: { recRos: [], userName: "" },
            sendChannel: "2",
            validateSend: {
                userName: [{ required: true, message: "请选择接收人", trigger: "blur" }],
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" },
                    { max: 30, message: "请控制在30字以内", trigger: "blur" },
                ],
                content: [{ required: true, message: "请输入详情", trigger: "blur" }],
            },
            // 选择接收人
            sendeeStatus: false,
            treeHeight: "",
            tableHeight: "",
            sendTableData: [],
            sendTableColumns: [
                {
                    type: "selection",
                    maxWidth: 60,
                    align: "center",
                },
                {
                    title: "序号",
                    type: "index",
                    align: "center",
                    maxWidth: 120,
                },
                {
                    title: "住房编号",
                    key: "houseCode",
                    align: "center",
                    minwidth: 120,
                },
                {
                    title: "姓名",
                    key: "name",
                    align: "center",
                    maxWidth: 140,
                },
                {
                    title: "性别",
                    key: "sex",
                    align: "center",
                    maxWidth: 140,
                },
                {
                    title: "手机号",
                    key: "phone",
                    align: "center",
                    maxWidth: 220,
                },
            ],
            sendLoading: false,
            sendPage: 1,
            sendTotal: 0,
            sendPageSize: 20,
            rightList: [],
            treeParams: {
                // status: 2, oemCode: parent.vue.oemInfo.oemCode
            },
            selectList: [],
            removeRightList: [],
            searchData: {
                name: "",
                phone: "",
                pageSize: 20,
                pageNum: 1,
                familyNo: "",
                userType: "",
                detailAddr: "",
                contact: "",
            },
            familyMemberIds: [],
        }
    },
    filters: {},

    methods: {
        textareaOnChange() {
            this.resTotal = Math.ceil(this.sendObj.content.length / 70) * this.resSendTotal
            this.resFee = this.resTotal * 0.07
        },
        goBack() {
            if (this.$route.query.name == "linehomeindex") {
                this.$router.push(`/smsrecordindex?isLineHome=true&name=${this.$route.query.name}`)
                return
            }
            if (this.$route.query.menuId) {
                if (this.$route.query.isLineHome) {
                    this.$router.push({
                        path: "/emailindex",
                        query: {
                            menuId: this.$route.query.menuId,
                            isLineHome: this.$route.query.isLineHome,
                        },
                    })
                    return
                } else {
                    this.$router.push({
                        path: "/emailindex",
                        query: {
                            menuId: this.$route.query.menuId,
                        },
                    })
                    return
                }
            }
            if (this.$route.query.isLineHome) {
                this.$router.push(`/residentdatabase`)
            }
        },
        // 选择接收人
        changeOpenTips(status) {
            this.openTips = status
        },
        // 校验机构是否开通短信功能
        massMessaging() {
            this.$get("/syaa/pc/sy/sms/checkSmsChannel", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200) {
                    this.sendStaus = true
                }
                if (res.code == 500) {
                    this.isOpen = true
                    this.sendStaus = true
                    // this.$Message.warning({
                    //   content:res.desc,
                    //   background:true
                    // })
                    this.changeOpenTips(true)
                }
            })
        },

        goOpen() {
            this.opLoading = true
            this.$post(
                "/syaa/pc/sy/smsChannel/submitChannel",
                {
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    channelId: "1",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "申请发送成功",
                        background: true,
                    })
                } else {
                    this.$Message.warning({
                        content: res.desc,
                        background: true,
                    })
                }
            })
            this.opLoading = false
            this.changeOpenTips(false)
        },
        notOpen() {
            this.changeOpenTips(false)
        },

        //保存接收人
        saveRightList() {
            console.log(this.rightList)
            this.sendObj.userName = ""
            this.sendObj.recRos = []
            let familyMemberIds = []
            this.rightList.map(item => {
                this.sendObj.userName = this.sendObj.userName ? this.sendObj.userName + "、" + item.name : item.name
                this.sendObj.recRos.push({
                    houseCode: item.houseCode,
                    houseName: item.regionGovernmentVo ? item.regionGovernmentVo.roadName + "-" + item.regionGovernmentVo.projectName + "-" + item.regionGovernmentVo.houseName : "",
                    mobile: item.phone,
                    userId: item.custGlobalId,
                    userName: item.name,
                })

                familyMemberIds.push(item.familyMemberId)
            })
            this.familyMemberIds = familyMemberIds
            let ids = []
            this.rightList.forEach(e => {
                ids.push(e.id)
            })
            ids = ids.join()
            console.log(this.rightList, "-----------------------保存联系人")
            if (!this.isOpen) this.getMsgNum(ids)
            this.sendStatusFn(false)
        }, // 计算短信数量费用
        getMsgNum(ids) {
            this.$post("/syaa/pc/sy/user/online/home/selectMemberSmsUser", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                ids,
            }).then(res => {
                if (res.code == 200) {
                    this.resFee = res.data.fee
                    this.resTotal = res.data.sendTotal
                    this.resSendTotal = res.data.sendTotal
                }
                if (res.code == 500) {
                    this.$Message.warning({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        tableSelect(arr) {
            this.selectList = arr
        },
        // 选中右侧
        selectRightList(e, index) {
            if (e.target.parentNode.classList.contains("active")) {
                e.target.parentNode.classList.remove("active")
                this.removeRightList.splice(index, 1)
            } else {
                e.target.parentNode.classList.add("active")
                this.removeRightList.push(this.rightList[index])
            }
        },
        // 删除
        delRightList() {
            if (this.removeRightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请从已选择列表中选中需要删除的接收人",
                })
                return
            }
            let arr = []
            this.removeRightList.map(item => {
                arr.push(item.id)
            })
            let arr2 = []
            this.rightList.map(item => {
                if (arr.indexOf(item.id) == -1) {
                    arr2.push(item)
                }
            })
            this.rightList = arr2
            this.removeRightList = []
        },
        //清空
        clearRightList() {
            this.rightList = []
            this.removeRightList = []
        },
        pushToRightList() {
            let arr = this.selectList.concat(this.rightList)
            this.rightList = this.$core.onlyList(arr, "id")
        },
        sendStatusFn(status) {
            this.sendeeStatus = status
        },
        sendSearch() {
            this.sendHadlePageSize({
                page: 1,
                pageSize: this.sendPageSize,
            })
        },
        sendReset() {
            this.treeParams.name = ""
            this.sendHadlePageSize({
                page: 1,
                pageSize: this.sendPageSize,
            })
        },
        getList(obj) {
            console.log("获取树结构", obj)
            if (obj.communityCode != this.treeParams.communityCode && this.rightList.length > 0) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>您正在切换社区，此操作将会清空已选择的接收人，是否继续？</p>",
                    okText: "确定",
                    cancelText: "取消",
                    onOk: () => {
                        this.treeParams = {
                            ...obj,
                            communityCode: this.treeParams.communityCode,
                            roadCode: this.treeParams.roadCode,
                            status: this.searchData.status, //必填
                            name: this.searchData.name,
                            mobile: this.searchData.phone,
                            familyNo: this.searchData.familyNo,
                            userType: this.searchData.userType,
                            detailAddr: this.searchData.detailAddr,
                            contact: this.searchData.contact,
                            oemCode: parent.vue.oemInfo.oemCode,
                            page: this.pageNum,
                            pageSize: this.pageSize,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            provinceCode: "",
                            cityCode: "",
                            countyCode: "",
                            streetCode: "",
                            buildingCode: "",
                            houseNo: "",
                        }
                        if (obj.regionType == "2" || obj.regionType == "3") {
                            this.treeParams.projectCode = ""
                            this.treeParams.buildingCode = obj.projectCode
                        }
                        if (obj.regionType == 0 || obj.regionType == 4) {
                            console.log("如果为0 4进", obj)
                            this.treeParams.projectCode = ""
                            this.treeParams.houseNo = obj.projectCode
                        }
                        this.treeParams.regionId = obj.regionId
                        this.treeParams.communityCode = obj.communityCode
                        this.treeParams.roadCode = obj.roadCode
                        this.treeParams.projectCode = obj.projectCode
                        this.sendHadlePageSize({
                            page: 1,
                            pageSize: this.sendPageSize,
                        })

                        this.rightList = []
                        this.removeRightList = []
                    },
                })
            } else {
                this.treeParams = {
                    ...obj,
                    communityCode: this.treeParams.communityCode,
                    roadCode: this.treeParams.roadCode,
                    status: this.searchData.status, //必填
                    name: this.searchData.name,
                    mobile: this.searchData.phone,
                    familyNo: this.searchData.familyNo,
                    userType: this.searchData.userType,
                    detailAddr: this.searchData.detailAddr,
                    contact: this.searchData.contact,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    provinceCode: "",
                    cityCode: "",
                    countyCode: "",
                    streetCode: "",
                    buildingCode: "",
                    houseNo: "",
                    // page: this.pageNum,
                    // pageSize: this.pageSize
                }
                this.treeParams.regionId = obj.regionId
                this.treeParams.communityCode = obj.communityCode
                this.treeParams.roadCode = obj.roadCode
                this.treeParams.projectCode = obj.projectCode
                if (obj.regionType == "2" || obj.regionType == "3") {
                    this.treeParams.projectCode = ""
                    this.treeParams.buildingCode = obj.projectCode
                }
                if (obj.regionType == 0 || obj.regionType == 4) {
                    console.log("如果为0 4进", obj)
                    this.treeParams.projectCode = ""
                    this.treeParams.houseNo = obj.projectCode
                    console.log("40params", this.treeParams)
                }
                this.sendHadlePageSize({
                    page: 1,
                    pageSize: this.sendPageSize,
                })
            }
        },
        sendHadlePageSize(obj) {
            this.sendLoading = true
            this.$get("/syaa/pc/sy/user/online/home/selectMailReceiverPage", {
                ...this.treeParams,
                page: obj.page,
                pageSize: obj.pageSize,
            })
                .then(res => {
                    if (res.code === "200" && res.dataList) {
                        this.sendTableData = res.dataList.map(item => {
                            if (item.sex) {
                                item.sex = item.sex == "1" ? "男" : "女"
                            }
                            this.rightList.map(subItem => {
                                if (item.id == subItem.id) {
                                    item._checked = true
                                }
                            })
                            return item
                        })
                        this.sendTotal = res.maxCount
                        this.sendPageSize = res.pageSize
                        this.sendPage = res.currentPage
                        this.sendLoading = false
                    } else {
                        this.sendLoading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.sendLoading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 群发消息
        groupSend() {
            this.$refs.sendForm.validate(status => {
                if (status) {
                    this.$Message.loading({
                        content: "正在发送，请稍等...",
                        duration: 0,
                    })
                    let smsStaffVo = {
                        businessCode: 4,
                        oemCode: parent.vue.oemInfo.oemCode,
                        staffName: parent.vue.loginInfo.userinfo.realName,
                        staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                        smsMsg: this.sendObj.content,
                    }
                    let communityMessageRo = {
                        title: "",
                        communityCode: this.treeParams.communityCode,
                        creator: parent.vue.loginInfo.userinfo.realName,
                        creatorId: parent.vue.loginInfo.userinfo.id,
                        oemCode: parent.vue.oemInfo.oemCode,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        messageType: "2",
                        ...this.sendObj,
                    }
                    console.log(this.familyMemberIds)
                    this.$post(
                        "/syshequ/api/sytop/pc/chatMessage/sendChatCommunityMessage",
                        {
                            communityMessageRo,
                            sendChannel: this.sendChannel,
                            familyMemberIds: this.familyMemberIds,
                            smsStaffVo,
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.destroy()
                                this.$Message.success({
                                    background: true,
                                    content: "发送成功",
                                })
                                this.cancelModalTip = false
                                this.sendStausFn(false)
                                this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                })
                                this.resTotal = 0
                                this.resFee = 0
                                this.resSendTotal = 0
                            } else {
                                this.$Message.destroy()
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.destroy()
                            this.$Message.error({
                                background: true,
                                content: "发送失败，请联系管理员处理",
                            })
                        })
                }
            })
        },
        sendStausFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>您正在关闭窗口，信息将不会保存，是否继续？</p>",
                    okText: "确定",
                    cancelText: "取消",
                    onOk: () => {
                        this.sendStaus = status
                        this.resTotal = 0
                        this.resFee = 0
                        this.resSendTotal = 0
                    },
                })
            } else {
                this.sendStaus = status
                this.treeParams = {
                    status: 2,
                    oemCode: parent.vue.oemInfo.oemCode,
                }
                this.sendObj = { recRos: [], userName: "" }
                this.rightList = []
                this.removeRightList = []
                this.selectList = []
                this.sendTableData = []
            }
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get("/sendmsg/pc/chat/communityMessage/queryCommunityMessagePage", {
                oemCode: parent.vue.oemInfo.oemCode,
                page: obj.page,
                pageSize: obj.pageSize,
                userName: this.userName,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            if (item.recList && item.recList.length > 0) {
                                item.recList.map(subItem => {
                                    let userName = subItem.houseName ? subItem.houseName + "-" + subItem.userName : subItem.userName
                                    item.userName = item.userName ? item.userName + " , " + userName : userName
                                })
                                item.gmtCreate = item.recList[0].gmtCreate ? this.$core.formatDate(new Date(item.recList[0].gmtCreate), "yyyy-MM-dd hh:mm:ss") : ""
                            }
                        })
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    created() {
        // this.treeHeight = String(window.innerHeight - 152);

        this.tableHeight = String(window.innerHeight - 210)
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
        LiefengTree,
    },
}
</script>

<style scoped lang="less">
// /deep/#toolsbarRight{
//   height: 30px;
// }
/deep/.ivu-modal-close {
    display: none;
}
/deep/.active {
    background: #19be6b;
    color: #fff;
}
// .select-table{
//   /deep/ .ivu-table-wrapper{
//     width: 1380px !important;
//   }
// }
.container-div {
    height: 100%;
    display: flex;
    width: 100%;
    .tree-div {
        width: 200px;
        height: 100%;
        /deep/.ivu-tree {
            height: calc(100vh - 155px);
            overflow: auto;
        }
    }
    .center-table {
        width: calc(100% - 400px - 240px);
        margin-left: 20px;
        // border: 1px solid red;
    }
    .right-table {
        margin-left: auto;
        width: 410px;
        height: calc(100vh - 127px);
        display: flex;
        .right-center {
            width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .right-right {
            width: 300px;
            border: 1px solid #dcdee2;
            overflow: auto;
        }
    }
}
.search {
    /deep/.ivu-form-item-content {
        margin-left: 0 !important;
    }
}
</style>
